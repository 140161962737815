import React from "react";
import { GameInfoList, GameInfoListItem } from "./styled";
type gameInfoTypes = {
  gameName: string;
  nickName: string | null;
  RoundId: number;
};

export const GameInfo = ({ gameName, nickName, RoundId }: gameInfoTypes) => {
  const TITLES = ["Game", "Dealer", "#"];
  const VALUES = [gameName, nickName, RoundId];

  return (
    <GameInfoList>
      {TITLES.map((title, index) => {
        return (
          <GameInfoListItem key={index}>
            <span className="title">{title}:</span>
            <span className="text">{VALUES[index]}</span>
          </GameInfoListItem>
        );
      })}
    </GameInfoList>
  );
};
