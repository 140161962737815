import styled from "styled-components";

export const TexasWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    
    width: 100%;
    padding: 20px;
    gap: 25px;

      > div:last-child {
          margin-left: auto;
      }
`;