import React, { useEffect, useState } from "react";
import {
  RouletteLastNumbers,
  RouletteLastNumbersItem,
  RouletteWrapper,
  RouletteWrapperBottom,
  RouletteWrapperBottomRight,
  WheelDirectionWrapper,
  WinnerContainer,
  WinnerNumber,
  WinnerNumberDetails,
  WinnerNumberDetailsItem,
} from "./styled";
import {
  BlackColors,
  Dozen_1,
  Dozen_2,
  Dozen_3,
  EvenNumbers,
  H_COLUMN_1,
  H_COLUMN_2,
  H_COLUMN_3,
  OddNumbers,
  RedColors,
} from "./config";
import { WheelNumbers } from "../../constants/wheelnumbers";
import { handleStatisticsData } from "../../utils/statistician";
import RouletteWheelSvg from "./assets/rouletteWheel.svg";
import RouletteRotateIcon from "./assets/rotate_left_icon.svg";

export const RouletteGame = ({ serverState, numbers, seconds }) => {
  const [stateNumbers, setStateNumbers] = useState<any>([]);
  useEffect(() => {
    const RESULT = handleStatisticsData(numbers);
    setStateNumbers(RESULT);
  }, [numbers]);

  const LAST_CARD = serverState?.LastCard;
  const SCANNER_INDEX = LAST_CARD?.ScannerIndex - 1;
  const WHEEL_ROTATE_STATUS = serverState?.Players?.[0]?.Direction;

  const WHEEL_STOPPED = WHEEL_ROTATE_STATUS === 0;
  const WHEEL_ROTATE_LEFT = WHEEL_ROTATE_STATUS === 1;
  const WHEEL_ROTATE_RIGHT = WHEEL_ROTATE_STATUS === -1;
  const WHEEL_ROTATE_DIRECTION = WHEEL_ROTATE_LEFT
    ? "left"
    : WHEEL_ROTATE_RIGHT
    ? "right"
    : "";

  const GAME_STATE = serverState.CurrentState;

  const RENDER_WIN_BALL = SCANNER_INDEX > -1;
  const WIN_BOX = SCANNER_INDEX > -1 ? WheelNumbers[SCANNER_INDEX] : null;

  const IS_RED = SCANNER_INDEX >= 0 && RedColors.includes(WIN_BOX?.num);
  const IS_BLACK = SCANNER_INDEX >= 0 && BlackColors.includes(WIN_BOX?.num);

  const COLORS = IS_RED ? "Red" : IS_BLACK ? "Black" : "Green";

  const IS_EVEN = SCANNER_INDEX >= 0 && EvenNumbers.includes(WIN_BOX?.num);
  const IS_ODD = SCANNER_INDEX >= 0 && OddNumbers.includes(WIN_BOX?.num);

  const EVEN_ODD = IS_EVEN ? "EVEN" : IS_ODD ? "ODD" : null;

  const IS_HIGH = SCANNER_INDEX >= 19 && SCANNER_INDEX <= 36;
  const IS_LOW = SCANNER_INDEX > 0 && SCANNER_INDEX <= 18;

  const HIGH_LOW = IS_HIGH ? "HIGH 19-36" : IS_LOW ? "LOW 1-18" : null;

  const IS_DOZEN_1 = SCANNER_INDEX >= 0 && Dozen_1.includes(WIN_BOX?.num);
  const IS_DOZEN_2 = SCANNER_INDEX >= 0 && Dozen_2.includes(WIN_BOX?.num);
  const IS_DOZEN_3 = SCANNER_INDEX >= 0 && Dozen_3.includes(WIN_BOX?.num);

  const DOZENS = IS_DOZEN_1
    ? "1st dozen"
    : IS_DOZEN_2
    ? "2nd dozen"
    : IS_DOZEN_3
    ? "3rd dozen"
    : null;

  const IS_COLUMN_1 = SCANNER_INDEX >= 0 && H_COLUMN_1.includes(WIN_BOX?.num);
  const IS_COLUMN_2 = SCANNER_INDEX >= 0 && H_COLUMN_2.includes(WIN_BOX?.num);
  const IS_COLUMN_3 = SCANNER_INDEX >= 0 && H_COLUMN_3.includes(WIN_BOX?.num);

  const COLUMNS = IS_COLUMN_1
    ? "1st column"
    : IS_COLUMN_2
    ? "2nd column"
    : IS_COLUMN_3
    ? "3rd column"
    : null;

  return (
    <RouletteWrapper>
      <WinnerContainer>
        {RENDER_WIN_BALL && WIN_BOX && GAME_STATE > 2 ? (
          <>
            <WinnerNumber winner={WIN_BOX}>
              <span>{SCANNER_INDEX}</span>
            </WinnerNumber>

            <WinnerNumberDetails>
              {COLORS && (
                <WinnerNumberDetailsItem color={COLORS}>
                  {COLORS}
                </WinnerNumberDetailsItem>
              )}

              {EVEN_ODD && (
                <WinnerNumberDetailsItem color="Black">
                  {EVEN_ODD}
                </WinnerNumberDetailsItem>
              )}

              {HIGH_LOW && (
                <WinnerNumberDetailsItem color="Black">
                  {HIGH_LOW}
                </WinnerNumberDetailsItem>
              )}

              {COLUMNS && (
                <WinnerNumberDetailsItem color="Black">
                  {COLUMNS}
                </WinnerNumberDetailsItem>
              )}

              {DOZENS && (
                <WinnerNumberDetailsItem color="Black">
                  {DOZENS}
                </WinnerNumberDetailsItem>
              )}
            </WinnerNumberDetails>
          </>
        ) : (
          <WinnerNumber winner={{ colorName: "#8f8f8f" }}>
            {GAME_STATE === 2 && WHEEL_STOPPED && (
              <span className="waiting">Wheel stopped.</span>
            )}

            {GAME_STATE === 2 && (WHEEL_ROTATE_LEFT || WHEEL_ROTATE_RIGHT) && (
              <WheelDirectionWrapper direction={WHEEL_ROTATE_DIRECTION}>
                <div className="left">
                  <img src={RouletteRotateIcon} alt="RouletteRotateIcon" />
                </div>

                <div className="wheel">
                  <img
                    className="wheel"
                    src={RouletteWheelSvg}
                    alt="RouletteWheelSvg"
                  />
                </div>

                <div className="right">
                  <img src={RouletteRotateIcon} alt="RouletteRotateIcon" />
                </div>
              </WheelDirectionWrapper>
            )}
          </WinnerNumber>
        )}
      </WinnerContainer>

      <RouletteWrapperBottom>
        {stateNumbers?.LastNumbers ? (
          <RouletteLastNumbers width="70">
            {stateNumbers?.LastNumbers.map((number, index) => {
              if (number < 0) {
                return "";
              }
              return (
                <RouletteLastNumbersItem
                  key={index}
                  background={WheelNumbers[number].colorName}
                >
                  <span>{number}</span>
                </RouletteLastNumbersItem>
              );
            })}

            <div className="title">Last Numbers</div>
          </RouletteLastNumbers>
        ) : (
          <span className="waitingForStats">waiting for statistics</span>
        )}

        {stateNumbers?.Hot ? (
          <RouletteWrapperBottomRight>
            <div className="title">5 hot - 5 cold</div>
            <div className="hot hotColdTitle">
              <span>hot</span>
            </div>
            <div className="cold hotColdTitle">
              <span>cold</span>
            </div>
            <RouletteLastNumbers width="100">
              {stateNumbers?.Hot.map((number, index) => {
                return (
                  <RouletteLastNumbersItem
                    key={index}
                    background={number.color}
                  >
                    <span>{number.Number}</span>
                  </RouletteLastNumbersItem>
                );
              })}
            </RouletteLastNumbers>
            <RouletteLastNumbers width="100">
              {stateNumbers?.Cold.map((number, index) => {
                return (
                  <RouletteLastNumbersItem
                    key={index}
                    background={number.color}
                  >
                    <span>{number.Number}</span>
                  </RouletteLastNumbersItem>
                );
              })}
            </RouletteLastNumbers>
          </RouletteWrapperBottomRight>
        ) : (
          ""
        )}
      </RouletteWrapperBottom>
    </RouletteWrapper>
  );
};
