const H_COLUMN_3 = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];
const H_COLUMN_2 = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
const H_COLUMN_1 = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];

const Dozen_1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const Dozen_2 = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
const Dozen_3 = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];

const EvenNumbers = [
  2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
];
const OddNumbers = [
  1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35,
];

const RedColors = [
  1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
];
const BlackColors = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];

export {
  RedColors,
  BlackColors,
  EvenNumbers,
  OddNumbers,
  H_COLUMN_1,
  H_COLUMN_2,
  H_COLUMN_3,
  Dozen_1,
  Dozen_2,
  Dozen_3,
};
