import styled, { css } from "styled-components";
type winNumberProps = {
  numbers?: any;
  winner?: any;
};
type winNumberDetailsProps = {
  numbers?: [];
  winner?: [];
};
type RouletteLastNumbersProps = {
  width: string;
};
type RouletteLastNumbersItemProps = {
  background?: string;
};

export const Text = styled.p`
  font-size: 40px;
  color: #f5b042;
`;
export const RouletteWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  padding: 16px;
  box-sizing: border-box;
  background-color: rgba(211, 211, 211, 0.7);
`;
export const RouletteWrapperBottom = styled.div`
  width: 100%;
  height: 30vh;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .waitingForStats {
    font-weight: bold;
    text-transform: uppercase;
    font-size: calc(2vw + 2vw);
  }
`;
export const RouletteWrapperBottomRight = styled.div`
  width: 25%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .hotColdTitle {
    position: absolute;
    background-color: red;

    top: 0;
    right: 100%;
    width: 30px;
    height: 50%;

    span {
      display: block;
      font-weight: bold;
      text-align: center;
      writing-mode: vertical-rl;
      text-transform: uppercase;
      line-height: calc(1vw + 1vh);
      font-size: calc(0.8vw + 0.8vh);

      top: 50%;
      position: absolute;
      transform: translateY(-50%) rotate(-180deg);
    }
  }
  .cold {
    top: auto;
    bottom: 0;
    background-color: blue;
  }
  .title {
    left: 0;
    bottom: 100%;
    position: absolute;
    text-transform: uppercase;
    color: hsl(48deg 100% 49%);
    font-size: calc(1vw + 1vh);
  }
`;
export const RouletteLastNumbers = styled.ul<RouletteLastNumbersProps>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
  position: relative;
  width: ${(props) => props.width}%;

  .title {
    left: 0;
    bottom: 100%;
    position: absolute;
    text-transform: uppercase;
    font-size: calc(1vw + 1vh);
    color: hsl(48deg 100% 49%);
  }
`;
export const RouletteLastNumbersItem = styled.li<RouletteLastNumbersItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.background || "grey"};

  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #ffffff;

  span {
    font-size: calc(1vw + 1vh);
    font-family: "Pathway Extreme", sans-serif;
  }
  span.percent {
    top: 0;
    right: 0;
    position: absolute;
    font-size: calc(0.4vw + 0.4vh);
  }
`;
export const WinnerContainer = styled.div`
  display: flex;

  width: 100%;
  height: 100%;
  background-color: grey;
`;
export const WinnerNumber = styled.div<winNumberProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 30vh;
  background-color: ${(props) => props.winner?.colorName || "grey"};
  span {
    color: #ffffff;
    font-size: calc(9vw + 9vh);
    &.waiting {
      text-transform: uppercase;
      font-size: calc(2.5vw + 2.5vh);
    }
  }
`;

export const WheelDirectionWrapper = styled.div<{direction?: any}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  .left,
  .right {
    width: 10vw;
    height: 10vw;
    opacity: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .wheel {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    transform: rotateY(180deg);
  }

  ${(props) =>
    props.direction === "left" &&
    css`
      .wheel {
        animation: rotateWheelLeft 7s linear infinite;
      }
      .left {
        animation: rotateWheelIcon 0.5s linear infinite;
      }
    `}

  ${(props) =>
    props.direction === "right" &&
    css`
      .wheel {
        animation: rotateWheelRight 7s linear infinite;
      }
      .right {
        animation: rotateWheelIcon 0.5s linear infinite;
      }
    `}
  
  @keyframes rotateWheelRight {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @keyframes rotateWheelLeft {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes rotateWheelIcon {
    0% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const WinnerNumberDetails = styled.div<winNumberDetailsProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  width: auto;
  height: max-content;
  padding: 15px;
  margin-left: 10px;
  background: #b2b2b270;
  box-sizing: border-box;
`;
export const WinnerNumberDetailsItem = styled.div`
  font-weight: bold;
  font-size: calc(2vw + 2vh);
  text-transform: uppercase;
  color: ${(props) => props.color};
  line-height: calc(2vw + 2vh);
`;
