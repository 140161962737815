import React from "react";
import {TexasWrapper} from "./styles";
import {TexasBox} from "./components/TexasBox";
import {RankNames} from "../../types";
interface IData {
  box: any;
  flop: any
}

type TexasProps = {
  data: IData;
  lastCard?: number;
}

export const Texas = (props: TexasProps) => {
  const {data, lastCard } = props;
  const {box, flop} = data;


  const component = (data, title, combination?) => {
    let comb = combination ? combination.map((x) => (x = RankNames[x])) : null;

    return data ? <TexasBox className={`TexasBox ${title}-box`} title={title} cards={data} lastCard={lastCard} comb={comb} /> : null
  }

  if(!box[0]){return null}

  return <TexasWrapper>
    {component(box[1].DealerBaseBox.Cards, "Dealer", box[1]?.Hand?.Hands)}
    {component(flop, "Flop")}
    {component(box[0].PlayerBox.Cards, "Seat 1", box[0]?.Hand?.Hands)}
  </TexasWrapper>
}