import styled from "styled-components";

export const GameInfoList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  top: 0;
  right: 20%;
  position: fixed;

  width: auto;
  padding: 5px;
  height: 110px;
  min-width: 100px;
  background-color: #2f0f0f;
  border-left: 2px solid grey;
`;

export const GameInfoListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 16px;
    display: inline-block;
    text-transform: capitalize;
    margin-right: 5px;
  }

  .text {
    color: yellow;
    font-size: 16px;
    text-shadow: 0 0 10px yellow;
  }
`;
