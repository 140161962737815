import React from "react";
import { RoundStateWrapper } from "./styled";
type props = {
  state: number;
};

const setRoundStateValue = (value) => {
  return value == 3
    ? "Round is Canceled"
    : value == 4
    ? "Round is failed"
    : value == 5
    ? "Round is Suspended"
    : value == 6
    ? "Round in Maintenance Mode"
    : "";
};
export const RoundState = ({ state }: props) => {
  const RENDER_ROUND_STATE = [3, 4, 5, 6].includes(state);

  const ROUND_STATE_VALUE = setRoundStateValue(state);

  if (RENDER_ROUND_STATE) {
    return (
      <RoundStateWrapper className="RoundStateWrapper">
        <p>{ROUND_STATE_VALUE}</p>
      </RoundStateWrapper>
    );
  }

  return null;
};
