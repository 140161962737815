import {GameStates, Language} from '../types'

export default {
  [Language.Russian]: {
    [GameStates.WaitingRoundStart]: 'Подождите, пока начнется раунд',
    [GameStates.WaitingForBet]: 'Ждите ставок',
    [GameStates.WaitingCard]: 'Сдать следующую карту',
    [GameStates.WaitingRebuy]: 'Ждите решений игроков',
    [GameStates.WaitingRebuyCards]: 'Сдать следующую карту прикупа',
    [GameStates.WaitingOpenDealerCards]: 'Открыть карты дилера',
    [GameStates.WaitingDealerCards]: 'Сдать следующую карту дилера',
    [GameStates.WaitingForWins]: 'В ожидании побед',
    [GameStates.WaitingRoundEnd]: 'Дождитесь окончания раунда',
    [GameStates.CalculateWins]: 'Подсчет выигрышей',
    [GameStates.RoundEnd]: 'Конец раунда'
  },
  [Language.Armenian]: {
    [GameStates.WaitingRoundStart]: 'Wait for round to start',
    [GameStates.WaitingForBet]: 'Սպասեք խաղադրույքներին',
    [GameStates.WaitingCard]: 'Deal',
    [GameStates.WaitingRebuy]: 'Waiting for players decision',
    [GameStates.WaitingRebuyCards]: 'Deal re-buy cards',
    [GameStates.WaitingOpenDealerCards]: 'Open dealer cards',
    [GameStates.WaitingDealerCards]: 'Deal dealer cards',
    [GameStates.WaitingForWins]: 'Wait for wins',
    [GameStates.WaitingRoundEnd]: 'Wait for round end',
    [GameStates.CalculateWins]: 'Calculating wins',
    [GameStates.RoundEnd]: 'Round end'
  },
  [Language.English]: {
    [GameStates.WaitingRoundStart]: 'New Round',
    [GameStates.WaitingForBet]: 'Betting Time',
    [GameStates.WaitingCard]: 'Deal card',
    [GameStates.WaitingRebuy]: 'Players Decision Time',
    [GameStates.WaitingRebuyCards]: 'Deal ...',
    [GameStates.WaitingOpenDealerCards]: 'Open dealer cards',
    [GameStates.WaitingDealerCards]: 'Deal dealer cards',
    [GameStates.WaitingForWins]: 'Wait for wins',
    [GameStates.WaitingRoundEnd]: 'Wait for round end',
    [GameStates.CalculateWins]: 'Win Calculation Time',
    [GameStates.RoundEnd]: 'Round end',
    [GameStates.WaitingPlayerSit]: 'Waiting Player Sit',
    [GameStates.WaitingForBurn]: 'Waiting Burn Cards',
    [GameStates.WaitingForDealerConfirmation]: 'Waiting Dealer Confirm',
    [GameStates.WaitingForShoeChangeConfirmation]: 'Waiting Shoe Change'
  }
}
