import React from "react";
import "./App.css";
import "./custom.css";
import queryString from "query-string";
import Card from "./components/Card";
import DealerActionMessages from "./messages/dealer-action";
import TimerBasedMessages from "./messages/timer-action";
import { GameStates, Language, TimerTypes } from "./types";
import moment from "moment";
import BackFace from "./assets/backface-pattern.jpeg";
import { Win } from "./utils/icons";
import Timer from "./components/GameTimer";
import { setCard } from "./cardsIndex";
import {
  Lucky_Baccarat,
  LuckyBottom,
  LuckyTop,
  LuckyWrapper,
} from "./styled/lucky_styled";
import { RoundState } from "./components/roundState";
import { RouletteGame } from "./Games/Roulette";
import { GameInfo } from "./components/GameInfo";
import {Texas} from "./Games";

const getQueryParams = () => queryString.parse(window.location.search);
const { gameId, tableId, dealerId, dealerToken } = getQueryParams();

const CONNECTION_URL = process.env.REACT_APP_WSS;
class App extends React.Component<any, any> {
  hubConnection = null as any;
  hubProxy = null as any;
  timer = null as any;
  playerBoxes = null as any;
  aliveInterval = null as any;
  ThirtyTwoCards = null as any;
  state = {
    gameStats: [],
    hasBettingTime: false,
    _32CardData: [],
    boxWinId: 0,
    alerts: [
      {
        AlertId: 1,
        AlertEng: "Mistake",
        AlertRus: "Mistake",
        Priority: 0,
        AlertSystem: "Dealer",
        bg: "red",
      },
      {
        AlertId: 2,
        AlertEng: "Refresh",
        AlertRus: "Refresh",
        Priority: 0,
        AlertSystem: "Dealer",
        bg: "grey",
      },
      {
        AlertId: 7,
        AlertEng: "Change_me",
        AlertRus: "Change_me",
        Priority: 0,
        AlertSystem: "Dealer",
        bg: "blue",
      },
      {
        AlertId: 3,
        AlertEng: "Talk",
        AlertRus: "Talk",
        Priority: 0,
        AlertSystem: "Dealer",
        bg: "green",
      },
    ],
    REBUY_CARDS: [],
    callDealerMessagesId: 1,
    BUYSIXDEALER: false,
    JOINED_TO_VIP: false,
    dealerNickName: "",
    playerNickname: "",
    roundCanceled: false,
    skipMessage: null,
    requestPending: false,
    showRoomCloseButton: false,
    avalaibleRebuyCards: [],
    waitingForFirstGameChange: true,
    serverState: {
      GameState: 0 as number,
      TableState: { Player: { Boxes: [] } },
    } as any,
    _gameChange: {
      GameState: 0 as number,
      TableState: { Player: { Boxes: [] } },
    } as any,
    language: Language.English as Language,
    activeTimerType: TimerTypes.NO_TIMER as TimerTypes,
    bettingTimeSeconds: 0,
    bettingTimeId: null,
    lastTimeStamp: null,
    timeStamp: null,
    flag: null,
    playerlang: false,

    callDealerMessages: [],
    callDealerSound: false,

    welcome: false,
    reScan: null,
    managerCard: false,

    count: 0,
    ActiveHands: [],
    CardsCount: [],
    GameState: 1,
    boxCount: 1,
    gameType: null,
    cardsOBJ: {} as any,

    CurrentBox: null,
    CurrentHand: null,

    Shoe: null,

    RoundId: 0,

    messageTextIndex: 100,
    clientChatText: "",
    box1: {} as any,
    box2: {} as any,
    box3: {} as any,
    box4: {} as any,
    box5: {} as any,

    isBlackJack: false,
    LuckySeven: false,
    TexasPoker: false,
    TexasPokerData: null,
    isThirtyTwoCard: false,
    AndarBahar: false,
    BoxObject32Card: null,
    MessageDateTime: null,
    AndarBaharArr: null,
    Joker: null,
    DragonTiger: false,
    DragonCard: null,
    TigerCard: null,
    Baccarat: false,
    BankerCards: null,
    PlayerCards: null,
    BankerCount: 0,
    PlayerCount: 0,
    NextCardFor: null,
    popUpAction: false,
    popUpActionText: null,
    isRoulette: null,
  };

  componentDidMount() {
    if ($ === undefined || $.hubConnection === undefined) {
      throw new Error(
        `The variable '$' or the.hubConnection() function are not defined... please check that the SignalR scripts have been loaded properly`
      );
    }

    this.hubConnection = $.hubConnection();
    this.hubConnection.url = CONNECTION_URL;
    this.hubConnection.qs = {
      dealerId,
      dealerToken,
      gameId,
      tableId,
    };

    this.hubProxy = this.hubConnection.createHubProxy("DealerHub");

    this.startConnection();

    window.addEventListener("offline", () => {
      console.log("Net error");
    });
    window.addEventListener("online", () => {
      console.log("Net resolved");
    });

    this.hubConnection.reconnecting(() => {
      console.log("Reconnecting");
    });

    this.hubConnection.reconnected(() => {
      console.log("Reconnected");
    });

    this.hubConnection.disconnected(() => {
      console.log("disconnected");

      setTimeout(() => {
        this.startConnection();
      }, 3000);
    }, 3000);

    this.hubProxy.on("alive", this.alive);
    this.hubProxy.on("joinalert", this.joinAlert);
    this.hubProxy.on("roundCanceled", this.roundCanceled);
    this.hubProxy.on("bettingTimeOpen", this.bettingTimeOpen);
    this.hubProxy.on("bettingTimeClosed", this.bettingTimeClosed);
    this.hubProxy.on("settleWin", this.settleWin);
    this.hubProxy.on("stateChange", this.stateChange);
    this.hubProxy.on("playerMessage", this.playerMessage);
    this.hubProxy.on("dealerMessage", this.dealerMessage);
    this.hubProxy.on("gameChange", this.gameChange);
    this.hubProxy.on("setdealer", this.setDealer);
    this.hubProxy.on("playerlanguage", this.playerLang);
    this.hubProxy.on("ScanCardMistake", this.ScanCardMistake);
    this.hubProxy.on("endRound", this.endRound);
    this.hubProxy.on("stats", this.stats);
  }

  stats = (data) => {
    console.log("stats", data);
    const STATS = data.split(",");

    this.setState({
      gameStats: STATS,
    });
  };

  endRound = (data) => {
    console.log("endRound", data);
    // const { Shoe } = this.state;
    //
    // if(Shoe.ShoeState === 2){
    //   this.setState({
    //     globalModal: true,
    //     globalModalText: true
    //   })
    // }
  };

  joinAlert = (data: any) => {
    let { callDealerMessages, callDealerMessagesId } = this.state;
    const D = new Date();
    const month =
      D.getMonth() + 1 < 10 ? "0" + (D.getMonth() + 1) : D.getMonth() + 1;
    const hour = D.getHours() < 10 ? "0" + D.getHours() : D.getHours();
    const minute = D.getMinutes() < 10 ? "0" + D.getMinutes() : D.getMinutes();
    const seconds = D.getSeconds() < 10 ? "0" + D.getSeconds() : D.getSeconds();
    const dateData =
      D.getDate() +
      "-" +
      month +
      "-" +
      D.getFullYear() +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      seconds;

    const newConnection = {
      DealerId: data.DealerId,
      DealerNickName: data.DealerNickName,
      GameId: data.GameId,
      GameName: data.GameName,
      PlayerId: data.PlayerId,
      PlayerNickname: data.PlayerNickname,
      TableId: data.TableId,
      TableName: data.TableName,
      date: dateData,
      index: callDealerMessagesId,
    };

    callDealerMessages.push(newConnection);

    if (data && data.Data && data.Data.Message === "WelcomeToTentoora") {
      this.setState({
        welcome: true,
        playerNickname: data.Data.PlayerNickname,
      });
    }

    this.setState(
      {
        callDealerSound: false,
        callDealerMessagesId: callDealerMessagesId + 1,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              callDealerMessages,
              callDealerSound: true,
            },
            () => {
              // this.setState({
              //   callDealerSound: true
              // })
            }
          );
        }, 500);
      }
    );
  };

  setDealer = (data: any) => {
    console.log(data);
    this.setState({
      dealerNickName: data.Nickname,
      managerCard: false,
    });

    if (this.state.welcome) {
      this.setState({
        welcome: false,
      });
    }
  };

  ScanCardMistake = (data: any) => {
    console.log("ScanCardMistake", data);
    if (data && data === "Rescan Last 2 or 3 Cards") {
      this.setState({
        reScan: data,
        managerCard: true,
      });
    }
  };

  roundCanceled = (data) => {
    console.log("roundCanceled", data);
    if (data.RoundId == 0) {
      return;
    }

    this.setState(
      {
        roundCanceled: true,
        skipMessage: data.SkipMessage,
      },
      () => {
        setTimeout(() => {
          this.setState({
            roundCanceled: false,
          });
        }, 3000);
      }
    );
  };

  alive = (e) => {
    console.log("serius");
  };

  stateChange = (data: any) => {
    console.log("stateChange", data);
  };

  gameChange = (data: any) => {
    this.setState({ waitingForFirstGameChange: false });
    if (!data) return;

    let _Data = data;

    if (typeof _Data === "string") {
      _Data = JSON.parse(data);
    }

    console.log("backupdate, ", _Data);

    let _type = _Data.Config.Type;
    let newAvalaibleRebuyCards = 0;
    let TimeStamp = this.state.timeStamp;

    for (let i = 0, len = _Data.Boxes && _Data.Boxes.length; i < len; i++) {
      newAvalaibleRebuyCards += _Data.Boxes[i].AllowedRebuysCount;
      if (
        !this.state.serverState.Config?.IsUserActionBasedTable &&
        newAvalaibleRebuyCards === 0 &&
        this.state.GameState === 11
      ) {
        newAvalaibleRebuyCards += 1;
      }
    }

    if (data.GameState !== 18 && this.state.popUpAction) {
      this.setState({
        popUpAction: false,
        popUpActionText: null,
      });
    }

    if (data.CurrentState === 18) {
      this.setState({
        popUpAction: true,
        popUpActionText: "Shoe Change Complete",
      });
    }

    if (TimeStamp && data.StateStartTime < TimeStamp) {
      return;
    } else {
      this.setState({
        timeStamp: _Data.StateStartTime,
      });
    }

    let TexasPoker = _type === 3;
    let LuckySeven = _type === 17;
    let isThirtyTwoCard = _type === 18;
    let AndarBahar = _type === 22;
    let DragonTiger = _type === 23;
    let Baccarat = _type === 24;
    let isRoulette = _type === 2;

    this.setState({
      serverState: _Data,
      _gameChange: _Data,
      avalaibleRebuyCards: newAvalaibleRebuyCards
        ? new Array(newAvalaibleRebuyCards).fill(1)
        : [],
      REBUY_CARDS: _Data ? _Data.RebuyCards : [],
      gameType: _Data.Config.Type,
      boxCount: _Data.Config.BoxCount,
      GameState: _Data.CurrentState,
      CurrentHand: _Data.CurrentHand,
      CurrentBox: _Data.CurrentBox,
      LuckySeven: LuckySeven,
      ActiveHands: _Data.ActiveHands,
      CardsCount: _Data.Cards,
      isThirtyTwoCard: isThirtyTwoCard,
      Room: _Data.Config.Name,
      AndarBahar: AndarBahar,
      RoundId: _Data.RoundId,
      Shoe: _Data.Shoe,
      DragonTiger: DragonTiger,
      Baccarat: Baccarat,
      isRoulette: isRoulette,
      TexasPoker: TexasPoker,
      TexasPokerData: {box: _Data.Boxes, flop: _Data.RebuyCards},
    });

    const getNode = (el) =>
      document.querySelectorAll(`[data-cdci=${CSS.escape(el)}]`);
    const lastIX =
      _Data.LastCard !== null && _Data.LastCard !== undefined
        ? _Data.LastCard.ScannerIndex
        : null;

    if (this.state.ActiveHands && this.state.ActiveHands.length > 0) {
      let boxCount = 0;

      boxCount = this.state.ActiveHands.length * 2 + 1;
      this.setState({
        count: boxCount,
      });
    }

    let main = document.getElementById("box-container");
    if (
      this.state.CardsCount &&
      this.state.GameState === 3 &&
      this.state.count > 0 &&
      this.state.count === this.state.CardsCount.length
    ) {
      main.style.backgroundColor = "green";
    } else if (this.state.Shoe && this.state.Shoe.ShoeState === 2) {
      main.style.backgroundColor = "red";
    } else {
      // main.style.backgroundColor = "#3f424a";
    }

    if (_Data.CurrentState >= 3) {
      if (lastIX !== null) {
        let LCard = document.querySelectorAll(`[data-cdci]`);

        for (let i = 0; i < LCard.length; i++) {
          LCard[i].classList.remove("animateLastCard");
        }

        if (lastIX !== 0) {
          // player
          let LCard = document.querySelectorAll(
            `[data-cdci=${CSS.escape(lastIX)}]`
          );
          if (LCard[0]) {
            LCard[0].classList.add("animateLastCard");
          }
        } else {
          let reBuyCards = _Data.RebuyCards && _Data.RebuyCards;

          if (reBuyCards && reBuyCards.length) {
            let lastReBuyCard = reBuyCards[reBuyCards.length - 1].ScannerIndex;
            let LCard = getNode(lastReBuyCard); // document.querySelectorAll(`[data-cdci=${CSS.escape(lastReBuyCard)}]`)
            LCard[0].classList.add("animateLastCard");
          }

          if (
            _Data.Boxes &&
            _Data.Boxes[_Data.Boxes.length - 1].Hand.Cards[
              _Data.Boxes[_Data.Boxes.length - 1]
            ]
          ) {
            let DealerCard =
              _Data.Boxes[_Data.Boxes.length - 1].Hand.Cards[
                _Data.Boxes[_Data.Boxes.length - 1].Hand.Cards.length - 1
              ].ScannerIndex;
            let LCard = getNode(DealerCard); // document.querySelectorAll(`[data-cdci=${CSS.escape(DealerCard)}]`)
            return LCard;
          }
          if (LCard[0]) {
            LCard[0].classList.add("animateLastCard");
          }
        }
      }
    }

    if (_Data.CurrentState === 6 && this.state.isBlackJack) {
      this.setState({
        activeTimerType: true,
      });
    }

    let playerData = _Data.Players;
    if (playerData && (DragonTiger || Baccarat)) {
      const B_R = playerData[0].BankerCards?.reverse();
      const P_R = playerData[0].PlayerCards;
      this.setState({
        DragonCard: playerData[0].DragonCard,
        TigerCard: playerData[0].TigerCard,
        BankerCards: B_R,
        BankerCount: playerData[0].BankerValue,
        PlayerCards: P_R,
        PlayerCount: playerData[0].PlayerValue,
        NextCardFor: playerData[0].NextCardFor,
      });
    }

    if ((isThirtyTwoCard || AndarBahar) && playerData) {
      let _arr = [];
      let _32CardObj = _Data.Players[0].HandCards;
      let JokerCard = _Data.Players[0].Joker;
      let _32CardArray = Object.values(_32CardObj);

      this.setState({
        AndarBaharArr: _32CardArray,
        Joker: JokerCard,
      });

      for (let i = 0; i < _32CardArray.length; i++) {
        const element = _32CardArray[i] as any;
        if (element && "Hand" in element) {
          let _innerArray = [];
          let _innerElem = element.Hand;
          for (let j = 0; j < _innerElem.length; j++) {
            const innerElement = _innerElem[j];
            _innerArray.push(innerElement);

            if (j + 1 === _innerElem.length) {
              _innerArray.push({ point: element.Value });
            }
          }

          _arr.push(_innerArray);
        } else {
          _arr.push([]);
        }
      }

      this.setState({ _32CardData: _arr.reverse() });
    }
  };

  bettingTimeOpen = (data: any) => {
    let prevState = this.state.serverState;
    prevState["CurrentState"] = data.CurrentState;

    this.setState({
      serverState: prevState,
    });

    if (data && data.TimeStamp < this.state.lastTimeStamp) {
      return;
    }

    if (data.bettingTimeId === this.state.bettingTimeId) {
      return;
    }

    clearInterval(this.timer);
    this.timer = null;

    if (data.Time > 0) {
      this.setState(
        {
          activeTimerType: data.Type,
          bettingTimeSeconds: data.Time / 1000,
          bettingTimeId: data.bettingTimeId,
          lastTimeStamp: data.TimeStamp,
          hasBettingTime: true,
        },
        () => {
          this.timer = setInterval(() => {
            this.setState(
              {
                bettingTimeSeconds: this.state.bettingTimeSeconds - 1,
              },
              () => {
                if (this.state.bettingTimeSeconds <= 0) {
                  clearInterval(this.timer);
                  this.timer = null;
                  this.setState({ activeTimerType: TimerTypes.NO_TIMER });
                }
              }
            );
          }, 1000);
        }
      );
    }
  };

  bettingTimeClosed = (data: any) => {
    if (data && data.TimeStamp < this.state.lastTimeStamp) {
      return;
    }

    if (data.Time > 0 && (data.CurrentState === 4 || data.CurrentState === 6)) {
      this.setState({ activeTimerType: TimerTypes.NO_TIMER });
    }

    clearInterval(this.timer);
    this.timer = null;
    this.setState({
      bettingTimeSeconds: 0,
      lastTimeStamp: data.TimeStamp,
      hasBettingTime: false,
    });
  };

  createIntervalForRemovingMessages = (e, time) => {
    setTimeout(() => {
      let el: any = document.getElementById(e);
      if (el) {
        console.log(el);
        el.style.opacity = 0;

        setTimeout(() => {
          el.remove();
        }, 1000);
      }
    }, time);
  };

  playerLang = (data: any) => {
    console.log("playerLang", data);
    if (data.Flag) {
      this.setState(
        {
          flag: data.Flag,
          playerlang: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              playerlang: false,
            });
          }, 3000);
        }
      );
    }
  };

  playerMessage = (data: any) => {
    const isVIP = this.state.serverState.Config
      ? this.state.serverState.Config.IsUserActionBasedTable
      : "";
    const searchSTR = (str, search) => str.search(search);
    const joined = searchSTR(data.Message, /[Joined]{6}/g);
    const { gameType } = this.state;

    // VIP => play sound
    if (isVIP && joined !== -1) {
      console.log("callDealer");
      this.setState(
        {
          JOINED_TO_VIP: true,
        },
        () =>
          setTimeout(() => {
            this.setState({ JOINED_TO_VIP: false });
          }, 2000)
      );
    }

    console.log("playerMessage", data);

    this.setState({ MessageDateTime: data.MessageDateTime });

    if (data.Message === "Joined To Table") {
      this.setState({
        playerNickname: data.PlayerNickname,
        flag: data.Flag,
      });
    } else if (data.Message === "Left The Table") {
      this.setState({
        playerNickname: "",
      });
    } else if (
      (gameType === 13 || gameType === 21) &&
      (data.Message === "Win" ||
        data.Message === "BonusWin" ||
        data.Message === "Push")
    ) {
      return null;
    } else {
      let { messageTextIndex, MessageDateTime } = this.state;
      messageTextIndex += 1;
      const parent = document.createElement("div");

      let time = moment(MessageDateTime).format("HH:mm");
      const messages = document.getElementsByClassName("messages-cont");

      const timeDiv = document.createElement("div");
      timeDiv.id = "MessageDateTime";
      // timeDiv.style.fontSize = "1.1vw"
      timeDiv.append(time);

      const el = document.createElement("span");
      el.innerText = data.PlayerNickname + ". ";

      const line = document.createElement("p");
      line.className = "playerMessage";
      line.style.color = "#fff";
      // line.style.fontSize = "1.8vw"
      line.append(el);
      line.append(data.Message);

      parent.id = messageTextIndex.toString();
      parent.append(timeDiv);
      parent.append(line);

      if (messages && messages[0]) {
        messages[0].prepend(parent);

        this.createIntervalForRemovingMessages(messageTextIndex, 300000);

        this.setState({ messageTextIndex });
      }
    }
  };

  dealerMessage = (data: any) => {
    console.log("dealerMessage", data);

    let Priority = data.Priority;
    let messageColor =
      Priority === 2
        ? "rgb(255 0 0 / 55%)"
        : Priority === 1
        ? "rgb(255 255 0 / 52%)"
        : "#000000"; //#a2a0a042
    let { messageTextIndex } = this.state;
    messageTextIndex += 1;

    const messages = document.getElementsByClassName("messages-cont");

    const el = document.createElement("span");
    el.innerText = "Manager.";
    console.log(`el`, el);
    const line = document.createElement("p");
    line.id = messageTextIndex.toString();
    line.style.backgroundColor = messageColor;
    // line.style.fontSize = "2vw"
    line.className = "playerMessage";
    line.append(el);
    line.append(data.Message);
    console.log(`line`, line);

    if (messages && messages[0]) {
      messages[0].prepend(line);

      this.createIntervalForRemovingMessages(messageTextIndex, 300000);

      this.setState({ messageTextIndex });
    }
  };

  settleWin = (data: any) => {
    console.log("settleWin", data);

    let removeWinFromHTML = (boxWinId) => {
      // eslint-disable-next-line
      let _removeWinTitle = null;
      _removeWinTitle = setTimeout(() => {
        let _winText = document.getElementById(boxWinId);

        if (_winText) {
          _winText.remove();
        } else {
        }
      }, 7000);
    };

    let removeTipFromHTML = (Id) => {
      // eslint-disable-next-line
      let _removeTipTitle = null;
      _removeTipTitle = setTimeout(() => {
        let _winText = document.getElementById(Id);

        if (_winText) {
          _winText.remove();
        } else {
        }
      }, 4000);
    };

    for (let i = 0; i < data.length; i++) {
      let _win =
        data[i].WinType === 1
          ? "Bonus win"
          : data[i].WinType === 2
          ? "Insure Win"
          : null;
      let _tip = data[i].WinType === 3 ? "TIP" : null;
      let _box =
        data[i].BoxIndex !== -1
          ? document.getElementById("box_" + (data[i].BoxIndex + 1))
          : null;
      let tipBox = document.getElementById("Tmessage");

      if (_win) {
        let _id = new Date().getTime().toString();
        let span = document.createElement("span");
        span.classList.add("boxWin");
        span.id = _id;
        span.innerHTML = _win;

        _box && _box.append(span);

        removeWinFromHTML(_id);
      }

      if (_tip) {
        let id = "TIP";
        let span = document.createElement("span");
        span.classList.add("tip");
        span.id = id;
        span.innerHTML = `New ${_tip}`;

        tipBox.append(span ? span : "");

        removeTipFromHTML(id);
      }
    }
  };

  startConnection = () => {
    this.hubConnection
      .start({ withCredentials: false })
      .done(() => {
        console.log("Connection successfully started!");

        this.setState({ gameType: this.hubConnection.qs.type });

        this.aliveInterval = setInterval(() => {
          if (navigator.onLine) {
            this.hubProxy.invoke("alive");
          } else {
            console.log("offline");
            clearInterval(this.aliveInterval);
          }
        }, 5000);
      })
      .fail(() => {
        console.warn("Could not connect");
      });
  };

  invokeAlertMessage = (message) => {
    this.setState({
      popUpAction: false,
    });
    if (message === "Refresh") {
      window.location.reload();
    } else {
      this.hubProxy.invoke("alert", { alertMessage: message });
    }
  };

  render() {
    const {
      alerts,
      hasBettingTime,
      gameType,
      serverState,
      language,
      activeTimerType,
      bettingTimeSeconds,
      roundCanceled,
      skipMessage,
      playerNickname,
      GameState,
      welcome,
      managerCard,
      reScan,
      LuckySeven,
      _gameChange,
      _32CardData,
      AndarBahar,
      AndarBaharArr,
      Joker,
      RoundId,
      DragonTiger,
      DragonCard,
      TigerCard,
      Baccarat,
      BankerCards,
      PlayerCards,
      waitingForFirstGameChange,
      PlayerCount,
      BankerCount,
      NextCardFor,
      popUpAction,
      popUpActionText,
      isRoulette,
      TexasPoker,
      TexasPokerData,
      gameStats,
    } = this.state;

    if (waitingForFirstGameChange) {
      return "";
    }

    if (!serverState) {
      return <h1>...</h1>;
    }

    const ThirtyTwoCard = gameType === 18;
    const TexasHoldemP2P = gameType === 21;

    const renderOrder = (num, bool) => {
      return {
        order: bool ? num : 5,
      };
    };

    let array32 = [11, 10, 9, 8];

    this.playerBoxes =
      serverState.Boxes && !TexasHoldemP2P
        ? [...serverState.Boxes].slice(0, -1)
        : serverState.Boxes;

    const allowDeal =
      serverState.CurrentState === GameStates.WaitingCard ||
      serverState.CurrentState === GameStates.WaitingRebuy ||
      serverState.CurrentState === GameStates.WaitingRebuyCards ||
      serverState.CurrentState === GameStates.WaitingOpenDealerCards ||
      serverState.CurrentState === GameStates.CalculateWins ||
      serverState.CurrentState === GameStates.WaitingDealerCards ||
      serverState.CurrentState === GameStates.WaitingForBurn ||
      serverState.CurrentState === GameStates.WaitingForShoeChangeConfirmation;

    const hold =
      activeTimerType === TimerTypes.REBUY_DECISION ||
      activeTimerType === TimerTypes.REBUY_CARDS ||
      activeTimerType === TimerTypes.BUY_SIX_DEALER_DECISION ||
      activeTimerType === TimerTypes.FlopCards ||
      activeTimerType === TimerTypes.RiverCard;

    let messageStyle = {
      background: allowDeal
        ? hold
          ? "red"
          : serverState.CurrentState === GameStates.WaitingRebuy
          ? "red"
          : serverState.CurrentState === GameStates.CalculateWins
          ? "#f5b042"
          : "hsla(137, 85%, 30%, 1)"
        : "black",
    };

    let message;

    message = hold
      ? TimerBasedMessages[language][String(activeTimerType)]
      : DealerActionMessages[language][String(serverState.CurrentState)];

    if (isRoulette) {
      message = hold
        ? TimerBasedMessages[language][String(activeTimerType)]
        : serverState.CurrentState === 16
        ? "Roulette crashed"
        : serverState.CurrentState === 2 && bettingTimeSeconds > 0
        ? "Bets open"
        : serverState.CurrentState === 2 && bettingTimeSeconds < 1
        ? "Spin ball"
        : serverState.CurrentState > 2 && serverState.CurrentState < 10
        ? "Waiting win ball"
        : DealerActionMessages[language][String(serverState.CurrentState)];
    }

    if (Number(activeTimerType) === 14) {
      messageStyle = { background: "hsla(137, 85%, 30%, 1)" };
      message = "Open cards of second box";
    }

    if (Number(activeTimerType) === 9) {
      messageStyle = { background: "red" };
      message = "Waiting for insurance desicion";
    }

    if (Number(activeTimerType) === 16) {
      message = "Deal Flop Cards";
    }

    if (GameState == 17) {
      message = "Waiting for dealer confirm";
    }

    const renderedObject = this.playerBoxes;
    let BoxObject =
      gameType === 1 || gameType === 4 || gameType === 8
        ? new Array(1).fill({})
        : gameType === 12 || gameType === 13
        ? new Array(7).fill({})
        : gameType === 9
        ? new Array(2).fill({})
        : gameType === 17
        ? new Array(1).fill({})
        : gameType === 21
        ? new Array(10).fill({})
        : new Array(false ? 5 : 2).fill({});
    let LuckylastCard = _gameChange.LastCard;
    this.ThirtyTwoCards =
      serverState.Players !== undefined
        ? Object.values(serverState.Players)[0]
        : null;
    let _wins = this.ThirtyTwoCards && this.ThirtyTwoCards.WinStakes[0];

    if (!ThirtyTwoCard && !LuckySeven && BoxObject && renderedObject) {
      for (let i = 0; i < BoxObject.length; i++) {
        let newObj = {};
        for (let index = 0; index < renderedObject.length; index++) {
          if (i === renderedObject[index].BoxIndex) {
            newObj = renderedObject[index];
          }
        }

        BoxObject[i] = newObj;
      }
    } else {
      if (LuckySeven) {
        BoxObject = [LuckylastCard];
      }
    }

    const __CurrentState = serverState.CurrentState;
    const __RoundState = serverState.RoundState;
    const __disabled = __RoundState === 5 || __RoundState === 6;

    // @ts-ignore
    return (
      <div className="App">
        <div className="left-content" id="left-section">
          <div className="box-container" id="box-container">
            <div className="message-timer-box">
              <Timer
                message={message}
                bettingTimeSeconds={bettingTimeSeconds}
                hasBettingTime={hasBettingTime}
                GameState={GameState}
                gameId={gameId}
                andar={AndarBahar}
              />
            </div>

            <div id="Tmessage"></div>

            <div className="player-box" style={renderOrder(3, false)}>
              <div className="box-content">
                {TexasPoker ? (<Texas data={TexasPokerData} lastCard={serverState?.LastCard?.ScannerIndex} />) : null}
                {LuckySeven &&
                  BoxObject &&
                  BoxObject.map((box: any, i: number) => {
                    if (LuckySeven && box) {
                      let _indexOfScanner = 0;

                      if (serverState?.LastCard?.ScannerIndex) {
                        _indexOfScanner =
                          serverState?.LastCard?.ScannerIndex - 1;
                      }
                      return (
                        <LuckyWrapper
                          key={i}
                          id="luckySevenGame"
                          className="luckySevenGame"
                        >
                          <LuckyTop>
                            <div
                              className={`topBox ${
                                box.WinStakes.length > 0 &&
                                box.WinStakes[0].BetType === 32
                                  ? "win_box_noBdrs winStyle"
                                  : ""
                              }`}
                            >
                              <span className="topBox-title">7 Up</span>

                              <div className="cardBox">
                                {box.WinStakes.length > 0 &&
                                box.WinStakes[0].BetType === 32 ? (
                                  <img
                                    src={setCard(_indexOfScanner)}
                                    className={`game_lucky`}
                                    alt="card"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div
                              className={`topCenter ${
                                box.WinStakes.length > 0 &&
                                box.WinStakes[0].BetType === 30
                                  ? "win_box_noBdrs winStyle"
                                  : ""
                              }`}
                            >
                              <span className="topCenter-title">7</span>

                              {box.WinStakes.length > 0 &&
                              box.WinStakes[0].BetType === 30 ? (
                                <img
                                  src={setCard(_indexOfScanner)}
                                  className={`game_lucky`}
                                  alt="card"
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <div
                              className={`topBox ${
                                box.WinStakes.length > 0 &&
                                box.WinStakes[0].BetType === 31
                                  ? "win_box_noBdrs winStyle"
                                  : ""
                              }`}
                            >
                              <span className="topBox-title">7 down</span>

                              <div className="cardBox">
                                {box.WinStakes.length > 0 &&
                                box.WinStakes[0].BetType === 31 ? (
                                  <img
                                    src={setCard(_indexOfScanner)}
                                    className={`game_lucky`}
                                    alt="card"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </LuckyTop>

                          <LuckyBottom className="mt_5p">
                            <div
                              className={`red ${
                                box.WinStakes.length > 0 &&
                                box.WinStakes[1].BetType === 33
                                  ? "win_box_noBdrs winStyle"
                                  : ""
                              }`}
                            >
                              <span className="title">Red</span>

                              <div className="cardBox">
                                {box.WinStakes.length > 0 &&
                                box.WinStakes[1].BetType === 33 ? (
                                  <img
                                    src={setCard(_indexOfScanner)}
                                    className={`game_lucky`}
                                    alt="card"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div
                              className={`black ${
                                box.WinStakes.length > 0 &&
                                box.WinStakes[1].BetType === 34
                                  ? "win_box_noBdrs winStyle"
                                  : ""
                              }`}
                            >
                              <span className="title">Black</span>

                              <div className="cardBox">
                                {box.WinStakes.length > 0 &&
                                box.WinStakes[1].BetType === 34 ? (
                                  <img
                                    src={setCard(_indexOfScanner)}
                                    className={`game_lucky`}
                                    alt="card"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </LuckyBottom>

                          <LuckyBottom>
                            <div
                              className={`odd ${
                                box.WinStakes.length > 0 &&
                                box.WinStakes[2].BetType === 35
                                  ? "win_box_noBdrs winStyle"
                                  : ""
                              }`}
                            >
                              <span className="title">Odd</span>

                              <div className="cardBox">
                                {box.WinStakes.length > 0 &&
                                box.WinStakes[2].BetType === 35 ? (
                                  <img
                                    src={setCard(_indexOfScanner)}
                                    className={`game_lucky`}
                                    alt="card"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div
                              className={`even ${
                                box.WinStakes.length > 0 &&
                                box.WinStakes[2].BetType === 36
                                  ? "win_box_noBdrs winStyle"
                                  : ""
                              }`}
                            >
                              <span className="title">Even</span>

                              <div className="cardBox">
                                {box.WinStakes.length > 0 &&
                                box.WinStakes[2].BetType === 36 ? (
                                  <img
                                    src={setCard(_indexOfScanner)}
                                    className={`game_lucky`}
                                    alt="card"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </LuckyBottom>
                        </LuckyWrapper>
                      );
                    }
                  })}

                {ThirtyTwoCard &&
                  array32.map((_num, _k) => {
                    const __win = _wins && array32[_k] + 29 === _wins.BetType;
                    const data_32 = _32CardData; // .reverse();
                    return (
                      <div className="numbers">
                        <div
                          key={_k}
                          className={`number number_${_k} ${
                            __win ? "winStyle win_box" : ""
                          }`}
                        >
                          <div className="items">{array32[_k]}</div>
                          <div className="pluses">|</div>
                          <div className="box ThirtyTwoCardCardBox">
                            {data_32 &&
                              data_32[_k] &&
                              data_32[_k].map((_c, _cKey) => {
                                if (!_c.point) {
                                  return (
                                    <img
                                      src={setCard(_c.ScannerIndex - 1)}
                                      className={`game_32 card_${_cKey}`}
                                      alt="card"
                                    />
                                  );
                                }

                                return <div className="points">{_c.point}</div>;
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {AndarBahar && (
                  <LuckyWrapper>
                    <LuckyTop className="andarBox">
                      <div
                        className={`topBox ${
                          _wins && _wins.BetType === 52 ? " winStyle" : ""
                        }`}
                      >
                        <span className="topBox-title">Bahar</span>

                        <div className="cardBox">
                          {AndarBaharArr ? (
                            AndarBaharArr[1].map((item, i) => {
                              return (
                                <img
                                  src={setCard(item.ScannerIndex - 1)}
                                  className={`game_lucky alignRight card_${i}`}
                                  alt="card"
                                />
                              );
                            })
                          ) : (
                            <img
                              src={BackFace}
                              alt="BackFace"
                              className={`backface_card`}
                            />
                          )}
                        </div>
                      </div>
                    </LuckyTop>

                    <LuckyTop className="joker_card">
                      <span className="topBox-title">Joker</span>

                      <div className="cardBox">
                        {Joker && Joker.ScannerIndex > 0 ? (
                          <img
                            src={setCard(Joker.ScannerIndex - 1)}
                            className={`game_lucky alignRight `}
                            alt="card"
                          />
                        ) : (
                          ""
                          // <img src={BackFace} alt="BackFace" className={`backface_card`} />
                        )}
                      </div>
                    </LuckyTop>

                    <LuckyTop className="andarBox">
                      <div
                        className={`topBox ${
                          _wins && _wins.BetType === 51 ? " winStyle" : ""
                        }`}
                      >
                        <span className="topBox-title">Andar</span>

                        <div className="cardBox">
                          {AndarBaharArr ? (
                            AndarBaharArr[0].map((item, i) => {
                              return (
                                <img
                                  src={setCard(item.ScannerIndex - 1)}
                                  className={`game_lucky alignRight card_${i}`}
                                  alt="card"
                                />
                              );
                            })
                          ) : (
                            <img
                              src={BackFace}
                              alt="BackFace"
                              className={`backface_card`}
                            />
                          )}
                        </div>
                      </div>
                    </LuckyTop>
                  </LuckyWrapper>
                )}

                {DragonTiger && (
                  <>
                    <LuckyWrapper
                      id="luckySevenGame"
                      className="luckySevenGame"
                    >
                      <LuckyTop>
                        <div
                          className={`topBox bg_blue ${
                            _wins && _wins.BetType === 62 ? " winStyle" : ""
                          } `}
                        >
                          <span className="topBox-title">Tiger</span>

                          <div className="cardBox ml_m30">
                            {TigerCard && TigerCard.ScannerIndex > 0 ? (
                              <img
                                src={setCard(TigerCard.ScannerIndex - 1)}
                                className={`game_lucky`}
                                alt="card"
                              />
                            ) : (
                              ""
                              // <img src={BackFace} alt="BackFace" className={`backface_card`} />
                            )}
                          </div>
                        </div>

                        <div className={`topBox tie_box bg_green `}>
                          <div
                            className={`topBox-title ${
                              _wins && _wins.BetType === 63
                                ? "tie winStyle"
                                : "tie"
                            }`}
                          >
                            Tie
                          </div>

                          <div
                            className={`topBox-title ${
                              _wins && _wins.BetType === 64
                                ? "suitedTie winStyle"
                                : "suitedTie"
                            }`}
                          >
                            Suited Tie
                          </div>
                        </div>

                        <div
                          className={`topBox bg_red ${
                            _wins && _wins.BetType === 61 ? " winStyle" : ""
                          } `}
                        >
                          <span className="topBox-title">Dragon</span>

                          <div className="cardBox mr_m30">
                            {DragonCard && DragonCard.ScannerIndex > 0 ? (
                              <img
                                src={setCard(DragonCard.ScannerIndex - 1)}
                                className={`game_lucky`}
                                alt="card"
                              />
                            ) : (
                              ""
                              // <img src={BackFace} alt="BackFace" className={`backface_card`} />
                            )}
                          </div>
                        </div>
                      </LuckyTop>
                    </LuckyWrapper>
                  </>
                )}

                {Baccarat && (
                  <LuckyWrapper id="luckySevenGame" className="luckySevenGame">
                    <LuckyTop className="BaccaraGameTop">
                      <div
                        className={`topBox ${
                          _wins && _wins.BetType === 65 ? " winStyle" : ""
                        }`}
                      >
                        <span
                          className={`topBox-title ${
                            NextCardFor === "Banker" ? " cardForThisBox" : ""
                          }`}
                        >
                          Banker {BankerCount}
                        </span>

                        <div className="cardBox">
                          {
                            BankerCards && BankerCards.length
                              ? BankerCards.map((item, i) => {
                                  return (
                                    <img
                                      src={setCard(item.ScannerIndex - 1)}
                                      className={`game_lucky left_card card_${BankerCards.length} C_${BankerCards.length}_${i}`}
                                      alt="card"
                                    />
                                  );
                                })
                              : ""
                            // <img src={BackFace} alt="BackFace" className={`backface_card`} />
                          }
                        </div>
                      </div>

                      <div
                        className={`topBox middleBox ${
                          _wins && _wins.BetType === 67 ? " winStyle" : ""
                        }`}
                      >
                        <span className="topBox-title">Tie</span>
                        <div className="lineBox"></div>
                      </div>

                      <div
                        className={`topBox ${
                          _wins && _wins.BetType === 66 ? " winStyle" : ""
                        }`}
                      >
                        <span
                          className={`topBox-title ${
                            NextCardFor === "Player" ? " cardForThisBox" : ""
                          }`}
                        >
                          Player {PlayerCount}
                        </span>

                        <div className="cardBox">
                          {
                            PlayerCards && PlayerCards.length
                              ? PlayerCards.map((item, i) => {
                                  return (
                                    <img
                                      src={setCard(item.ScannerIndex - 1)}
                                      className={`game_lucky right_card card_${i}`}
                                      alt="card"
                                    />
                                  );
                                })
                              : ""
                            // <img src={BackFace} alt="BackFace" className={`backface_card`} />
                          }
                        </div>
                      </div>
                    </LuckyTop>

                    <Lucky_Baccarat>
                      <div className="title">BONUS Wins</div>

                      <ul className="bonusList">
                        <li
                          className={`bonusList-item ${
                            _wins && _wins.BetType === 68 ? " winStyle" : ""
                          }`}
                        >
                          Player Pair
                        </li>
                        <li className="devider"></li>
                        <li
                          className={`bonusList-item ${
                            _wins && _wins.BetType === 69 ? " winStyle" : ""
                          }`}
                        >
                          Banker Pair
                        </li>
                        <li className="devider"></li>
                        <li
                          className={`bonusList-item ${
                            _wins && _wins.BetType === 70 ? " winStyle" : ""
                          }`}
                        >
                          Perfect Pair
                        </li>
                        <li className="devider"></li>
                        <li
                          className={`bonusList-item ${
                            _wins && _wins.BetType === 71 ? " winStyle" : ""
                          }`}
                        >
                          AnyPair Pair
                        </li>
                      </ul>
                    </Lucky_Baccarat>
                  </LuckyWrapper>
                )}

                {isRoulette && (
                  <RouletteGame
                    serverState={serverState}
                    numbers={gameStats}
                    seconds={bettingTimeSeconds}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="right-content">
          <div className="right-content-top">
            <GameInfo
              RoundId={RoundId}
              gameName={serverState?.Config?.Name}
              nickName={serverState?.Config?.DealerNickName}
            />

            {alerts && (
              <ul className="actions">
                {alerts.map((item, key) => {
                  return (
                    <li>
                      <button
                        key={key}
                        disabled={__disabled}
                        style={
                          __disabled
                            ? { cursor: "not-allowed", opacity: 0.7 }
                            : {}
                        }
                        className={`action-btn bg_${item.bg}`}
                        onClick={
                          __disabled
                            ? () => {}
                            : () => this.invokeAlertMessage(item.AlertRus)
                        }
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {" "}
                        {item.AlertRus}{" "}
                      </button>
                    </li>
                  );
                })}
                <li className="big-button card_btn bg_yellow">
                  <button
                    key={1000}
                    className="action-btn"
                    disabled={__disabled}
                    style={
                      __disabled ? { cursor: "not-allowed", opacity: 0.7 } : {}
                    }
                    onClick={
                      __disabled
                        ? () => {}
                        : () => this.invokeAlertMessage("Card")
                    }
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Card
                  </button>
                </li>
              </ul>
            )}
          </div>

          <div className="right-content-bottom">
            <div className="msg-box">
              <div className="msg-box-title">Chat with Players/Manager</div>

              <div className="messages-cont"> </div>
            </div>

            <ul className="actions actions-bottom">
              {__CurrentState === 17 ? (
                <>
                  <li className="big-button confirm_btn bg_green">
                    <button
                      key={1000}
                      className="action-btn"
                      disabled={__disabled}
                      style={
                        __disabled
                          ? { cursor: "not-allowed", opacity: 0.7 }
                          : {}
                      }
                      onClick={
                        __disabled
                          ? () => {}
                          : () => this.invokeAlertMessage("Confirm")
                      }
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Confirm
                    </button>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>

        <RoundState state={__RoundState} />

        {popUpAction && (
          <div className="popUpAction">
            <div className="title">{popUpActionText}</div>

            <button
              disabled={__disabled}
              style={__disabled ? { cursor: "not-allowed", opacity: 0.7 } : {}}
              className={`action-btn `}
              onClick={
                __disabled
                  ? () => {}
                  : () => this.invokeAlertMessage("ShoeChangeComplete")
              }
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              close
            </button>
          </div>
        )}

        {roundCanceled && !skipMessage && (
          <div className="cancelRound">Round has been canceled </div>
        )}

        {welcome && (
          <div className="welcome">
            Dealer Card <br /> {"NickName: " + playerNickname}
          </div>
        )}

        {managerCard && <div className="cardMistake">{reScan}</div>}
      </div>
    );
  }
}
export default App;
