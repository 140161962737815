import styled from "styled-components";

export const TexasCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    min-width: 300px;

    padding: 0 10px;
    border-left: 2px solid grey;
    
    &.Flop-box {
        min-width: 700px;
    }
    
    .title {
        font-size: 40px;
        line-height: 44px;
        font-weight: bold;
        padding: 0 0 10px;
        margin: 0 0 5px;
        border-bottom: 2px solid green;
        text-transform: uppercase;
    }
`;export const TexasCardsInnerWrapper = styled.div`
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const CombinationWrapper = styled.div`
    min-height: 30px;
    padding-bottom: 5px;
    
    .combination {
        font-size: 25px;
        line-height: 28px;
        font-weight: bold;
        text-transform: uppercase;
    }
`;

export const TexasCardImageWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 140px;
    min-width: 110px;
    img.texas_card {
        
        width: 100%;
    } 
    
    &.animateLastCard {
        border-radius: 30px;
        box-shadow: 0 0 50px brown;
    }
`;