const _list = [
  0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24,
  16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26,
];
const _colorRed = [
  1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
];
const _colorBlack = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];
const _from1to18 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
];
const _from19to36 = [
  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
];
const _first12 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const _second12 = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
const _third12 = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
const _column1 = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];
const _column2 = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
const _column3 = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
const _tier = [33, 16, 24, 5, 10, 23, 8, 30, 11, 36, 13, 27];
const _orphelins = [17, 34, 6, 1, 20, 14, 31, 9];
const _zero = [12, 35, 3, 26, 0, 32, 15];
const _voisins = [
  22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25,
];

export {
  _list,
  _colorRed,
  _colorBlack,
  _tier,
  _orphelins,
  _zero,
  _voisins,
  _from1to18,
  _from19to36,
  _first12,
  _second12,
  _third12,
  _column1,
  _column2,
  _column3,
};
