import styled from "styled-components";

const FLEX_COL_TOP = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
}

const LuckyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    font-family: 'Pathway Extreme', sans-serif;
  }

  .cardBox {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    img {
      max-width: 100%;
      height: 100%;
    }
    .backface_card {
      width: auto;
      height: 80%;
      max-width: initial;
    }
  }
  
  .bg_blue {
    background-color: rgba(0, 0, 255, 1);
  }
  
  .bg_red {
    background-color: rgba(255, 0, 0, 1);
  }
  
  .bg_green {
    background-color: green;
  }
  
  .ml_m30 img {
    margin-left: -30%;
  }

  .mr_m30 img {
    margin-right: -30%;
  }
  
  .backface_card {
    padding: 5px;
    border-radius: 12px;
    background: #FCFCFC;
  }
  
  .tie_box {
    left: 50%;
    width: 37% !important;
    position: absolute;
    transform: translateX(-50%);
    -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%;
    .topBox-title {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tie {
      border-bottom: 1px solid #FCFCFC;
    }
    .suitedTie {
      border-top: 1px solid #FCFCFC;
    }
  }
`;

const LuckyTop = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  
  height: 60%;

  position: relative;
  .topBox {
    text-align: center;
    width: 50%;
    height: 100%;
    border: 1px solid whitesmoke;
    ${FLEX_COL_TOP};
    &-title {
      font-size: 60px;
      text-transform: uppercase;
    }
  }
  
  .topCenter {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    left: 50%;
    width: 22vw;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #FCFCFC ;
    transform: translateX(-50%);
    background-color: rgba(0, 128, 0, 1);
    &-title {
      font-size: 100px;
    }
  }
  
  .topRight {
    text-align: center;
    width: 50%;
    height: auto;
    padding-bottom: 20px;
    border: 1px solid whitesmoke;
  }
  
  &.BaccaraGameTop {
    .topBox {
      border: none;
      border-bottom: 2px solid grey;

      .lineBox {
        width: 3px;
        height: 80%;
        background-color: grey;
      }
    }
    .middleBox {
      width: 10%;
    }
    .cardBox {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      img {
        height: 50%;
        &.backface_card {
          height: 70%;
        }
      }
      .right_card.card_2 {
        transform: rotate(90deg) translate(25%, -17%);
      }
      .left_card.card_3.C_3_0 {
        transform: rotate(90deg) translate(25%, 17%);
      }
    }
  }
  
  &.joker_card {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }
  
  &.andarBox {
    width: 100%;
    .topBox {
      width: 100%;
    }
    .cardBox {
      display: flex;
      flex-wrap: nowrap;
      img {
        width: 9vw;
        height: auto;
      }
      img:not(:first-child) {
        margin-left: -4.5vw;
        border-left: 1px solid grey;
        border-radius: 20px 0 0 20px;
      }
    }
  }
`;
const LuckyBottom = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30%;
  &.mt_5p {
    margin-top: 5%;
  }
  .black,
  .red,
  .odd,
  .even {
    text-align: center;
    width: 50%;
    height: auto; // 100%;
    border: 1px solid whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .black {
    border-right: none;
    background-color: black;
  }
  .even {
    border-right: none;
  }
  .red {
    background-color: red;
  }
  .title {
    font-size: 29px;
    line-height: 34px;
    display: block;
    text-transform: uppercase;
  }
`;

const Lucky_Baccarat = styled.div`
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid grey;
  
  .title {
    width: 22%;
    font-size: 35px;
    margin-right: 10px;
    padding: 10px 20px;
    text-transform: uppercase;
    border-right: 2px solid grey;
    text-align: right;
  }
  .bonusList {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    height: 100%;
    &-item {
      font-size: 35px;
      padding: 10px 10px;
      list-style-type: none;
      text-transform: uppercase;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      text-align: center;
    }
    .devider {
      width: 2px;
      height: 50%;
      transform: translateY(50%);
      background-color: red;
    }
  }
`;

export { LuckyWrapper, LuckyTop, LuckyBottom, Lucky_Baccarat };
