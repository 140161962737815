import styled from "styled-components";

export const RoundStateWrapper = styled.div`
  width: 80%;
  height: calc(100% - 120px);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  left: 0;
  top: 120px;
  position: fixed;
  z-index: 999999;

  background-color: rgba(0, 0, 0, 0.5);

  p {
    margin: 0;
    padding: 0;
    color: red;
    font-size: 90px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;

    text-shadow: 0 0 11px #000000;
  }
`;
