import {TimerTypes, Language} from '../types'

export default {
  [Language.English]: {
    [TimerTypes.NO_TIMER]: '',
    [TimerTypes.REBUY_CARDS]: 'Waiting for players decision',
    [TimerTypes.REBUY_DECISION]: 'Waiting for players decision',
    [TimerTypes.BUY_SIX_DEALER_DECISION]: 'Waiting for players decision',
    [TimerTypes.PlayerSit]: 'Waiting Player Sit',
    [TimerTypes.FlopCards]: 'Waiting Burn Cards',
    [TimerTypes.TurnCard]: 'Waiting Dealer Confirm',
    [TimerTypes.RiverCard]: "Waiting River Card"
  },
  [Language.Armenian]: {
    [TimerTypes.NO_TIMER]: '',
    [TimerTypes.REBUY_DECISION]: 'Ждите решений игроков',
    [TimerTypes.BUY_SIX_DEALER_DECISION]: 'Ждите решений игроков',
  },
  [Language.Russian]: {
    [TimerTypes.NO_TIMER]: '',
    [TimerTypes.REBUY_CARDS]: 'Ждите решений игроков',
    [TimerTypes.REBUY_DECISION]: 'Ждите решений игроков',
    [TimerTypes.BUY_SIX_DEALER_DECISION]: 'Ждите решений игроков',
  }
}
