import React, { memo } from "react";
import styled, { keyframes } from "styled-components";

function HasTimerFunctionChanged(prevProps, nextProps) {
  let _prevTimerSeconds = prevProps.hasBettingTime;
  let _nextTimerSeconds = nextProps.hasBettingTime;

  return _prevTimerSeconds === _nextTimerSeconds;
}

function TimerFunction(props) {
  const { bettingTimeSeconds, GameState } = props;

  const __color = GameState === 2 ? "green" : "darkred";

  const anim = keyframes`
  0% {
      width: 100%;
      background-color: ${__color};
  }
  70% {
      width: 30%;
      background-color: ${__color};
  }
  72% {
      width: 28%;
      background-color: ${__color};
  }
  90% {
      width: 10%;
      background-color: ${__color};
  }
  91% {
      width: 9%;
      background-color: ${__color};
  }
  100% {
      width: 0%;
      background-color: ${__color};
  }
  `;

  const TimerSection = styled.div`
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999;
    opacity: 1;
    animation: ${anim} ${(props) => props.seconds || 0}s linear both;
  `;

  return <TimerSection seconds={bettingTimeSeconds} />;
}

export default React.memo(TimerFunction, HasTimerFunctionChanged);
