
export enum Ranks {
    A = 'A',
    Two = '2',
    Three = '3',
    Four = '4',
    Five = '5',
    Six = '6',
    Seven = '7',
    Eight = '8',
    Nine = '9',
    Ten = '10',
    Jack = 'J',
    Queen = 'Q',
    King = 'K',
  }
  
  export enum Suits {
    CLUBS = 'clubs',
    DIAMONDS = 'diamonds',
    HEARTS = 'hearts',
    SPADES = 'spades',
  }
  
  export enum GameStates {
    WaitingForInit = 0, // no game
    WaitingRoundStart = 1,
    WaitingForBet = 2,
    WaitingCard = 3,
    WaitingRebuy = 4,
    WaitingRebuyCards = 11,
    WaitingOpenDealerCards = 5,
    WaitingDealerCards = 6,
    WaitingForWins = 7,
    WaitingRoundEnd = 8,
    CalculateWins = 10,
    RoundEnd = 12,
    WaitingPlayerSit = 15,
    WaitingForBurn = 16,
    WaitingForDealerConfirmation = 17,
    WaitingForShoeChangeConfirmation = 18
  }
  
  export enum BoxStates {
    WaitingToSit = 0,
    WaitingForBet = 1,
    WaitingForCards = 2,
    WaitingForSixCard = 3,
    WaitingForRebuyCards = 4,
    WaitingForInsurance = 5,
    WaitingForWin = 6,
    WaitingStateEnd = 7,
    WaitingPlayerRebuyAction = 8,
    WaitingPlayerRebuyCardAction = 9,
    WaitingPlayerRebuyInsuranceAction = 10,
    WaitingPlayerBuyDealerCard = 11,
    WaitingCall = 12,
    WaitingRoundEnd = 13,
    ForDealerCard = 14
  }
  
  export enum BetType {
    Ante = 0,
    Rebuy = 1,
    BuySix = 2,
    Insurance = 3,
    BuySixForDealer = 4,
    Call = 5,
  }
  
  export enum TimerTypes {
    NO_TIMER = -1,
    NEXT_CARD = 2, // ??
    REBUY_CARDS = 4, // ??
    OPEN_DEALER_CARDS_TIMEOUT = 5,
    BUY_SIX_DEALER_DECISION = 6,
    REBUY_DECISION = 11, // ??
    PlayerSit = 15,
    FlopCards = 16,
    TurnCard = 17,
    RiverCard = 18
  }
  
  export enum HandRanks {
    NONE = -1,
    ROYAL_FLUSH = 0,
    STRAIGHT_FLUSH = 1,
    FOUR_OF_A_KIND = 2,
    FULL_HOUSE = 3,
    FLUSH = 4,
    STRAIGHT = 5,
    THREE_OF_A_KIND = 6,
    TWO_PAIR = 7,
    ONE_PAIR = 8,
    HIGH_CARD = 9,
    ACE_KING = 10,
    THREE_PAIR = 11,
  }
  
  export interface BetData {
    BetType: BetType,
    BoxId: number,
    ActionType: number, // always 0 - bet
    BetInfo: string,
    BetAmount: number,
    RoundId: string,
  }
  
  export interface TransactionResult {
    PlayerId: number,
    TransactionId: number,
    ErrorCode?: number,
    Balance: number,
  }
  
  export interface SettleWinData {
    PlayerId: number,
    BoxId: number,
    ConnectionId: number,
    tableId: number,
    Balance: number,
    WinAmount: number,
    PlayerData: object,
  }
  
  export enum Language {
    English,
    Armenian,
    Russian,
  }

export enum RankNames {
  "Royal Flush" = 0,
  "Straight Flush" = 1,
  "Four of a Kind" = 2,
  "Full House" = 3,
  "Flush" = 4,
  "Straight" = 5,
  "Three of a Kind" = 6,
  "Two Pairs " = 7,
  "Pair" = 8,
  "High Card" = 9,
  "Ace King" = 10,
}
