import React from "react";
import {CombinationWrapper, TexasCardImageWrapper, TexasCardsInnerWrapper, TexasCardsWrapper} from "./styles";
import {setCard} from "../../../../cardsIndex";
import classnames from "classnames";
import BackFace from "../../../../assets/backface-pattern.jpeg";

type TexasCardsProps = {
  cards: any;
  title: string;
  className?: string;
  lastCard?: number;
  comb?: string;
}
export const TexasBox = (props: TexasCardsProps) => {
  const {cards, title,className, lastCard, comb } = props;

  const CardReader = React.useCallback((card) => {
    return card.ScannerIndex === 0 ? BackFace:  setCard(card.ScannerIndex-1)
  }, []);

  return (
    <TexasCardsWrapper className={className}>
      <span className="title">{title}</span>

      <CombinationWrapper>
        {comb ? (
          <span className="combination">{comb}</span>
        ) : null}
      </CombinationWrapper>

      <TexasCardsInnerWrapper>
        {cards && cards.map((item,index) => {
          return (
            <TexasCardImageWrapper
              className={classnames(  {
                animateLastCard: lastCard === item.ScannerIndex
              })}
            >
              <img
                key={index}
                src={CardReader(item)}
                className="texas_card"
                alt="card"
              />
            </TexasCardImageWrapper>

          )
        })}
      </TexasCardsInnerWrapper>

    </TexasCardsWrapper>
  )
}