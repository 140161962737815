import React from "react";
import TimerFunction from "./TimerLine";

function HasCircleBoxChanged(prevProps, nextProps) {
  let _prevTimerSeconds = prevProps.bettingTimeSeconds;
  let _nextTimerSeconds = nextProps.bettingTimeSeconds;

  let _prevmessage = prevProps.message;
  let _nextmessage = nextProps.message;

  let _prevPropsGameId = prevProps.gameId;
  let _nextPropsGameId = nextProps.gameId;

  let _prevPropsAndar = prevProps.andar;
  let _nextPropsAndar = nextProps.andar;

  return (
    _prevTimerSeconds === _nextTimerSeconds &&
    _prevmessage === _nextmessage &&
    _prevPropsAndar === _nextPropsAndar &&
    _prevPropsGameId === _nextPropsGameId
  );
}

function setMessage({ GameState, andar, gameId, message }) {
  return GameState === 15 && andar
    ? "Shuffle time"
    : gameId === 13 && GameState === 6
    ? "Deal Joker Card"
    : andar && GameState === 6
    ? "Deal Joker"
    : message;
}

function Index(props) {
  const {
    message,
    bettingTimeSeconds,
    hasBettingTime,
    GameState,
    gameId,
    andar,
  } = props;

  const MESSAGE = setMessage({ GameState, andar, gameId, message });
  const CLASS_TIMER =
    GameState === 3 ||
    GameState === 11 ||
    (GameState === 6 && bettingTimeSeconds === 0 && message)
      ? "TimerFunctionParentWithoutSeconds"
      : "TimerFunctionParent";

  return (
    <>
      <div className="message">
        <h2>{MESSAGE}</h2>

        <div className={CLASS_TIMER}>
          <TimerFunction
            GameState={GameState}
            bettingTimeSeconds={bettingTimeSeconds}
            hasBettingTime={hasBettingTime}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(Index, HasCircleBoxChanged);
