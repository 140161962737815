export const WheelNumbers = [
  {
    num: 0,
    color: "#37B620",
    colorName: "green",
  },
  {
    num: 1,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 2,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 3,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 4,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 5,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 6,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 7,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 8,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 9,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 10,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 11,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 12,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 13,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 14,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 15,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 16,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 17,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 18,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 19,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 20,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 21,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 22,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 23,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 24,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 25,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 26,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 27,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 28,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 29,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 30,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 31,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 32,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 33,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 34,
    color: "#AB1111",
    colorName: "red",
  },
  {
    num: 35,
    color: "#000000",
    colorName: "black",
  },
  {
    num: 36,
    color: "#AB1111",
    colorName: "red",
  },
];
