import { _list } from "./statsConfig";
import { WheelNumbers } from "../constants/wheelnumbers";
export const handleStatisticsData = (numbers) => {
  const _arr = [];
  const _result = {} as any;
  const _lastNumbers = numbers;

  if (!(_lastNumbers.length > 0)) {
    return;
  }

  let _hot = [],
    _cold = [];

  for (let i = 0; i < _list.length; i++) {
    const _n = _list[i];
    const _count = _lastNumbers.filter((n) => n == _n).length;

    _arr.push({
      Number: _n,
      Count: _count,
      color: WheelNumbers[_n].colorName,
      Percentage: (numbers.length * _count) / 100,
    });
  }

  const hotCold = _arr.sort(function (a, b) {
    return a.Count - b.Count;
  });

  hotCold.slice(-5).map((x) => _hot.push(x));
  hotCold.slice(0, 5).map((x) => _cold.push(x));

  _result.Hot = _hot.reverse();
  _result.Cold = _cold;
  _result.LastNumbers = numbers.slice(0, 10);

  return _result;
};
